<template>
  <div>
    <div class="d-flex vh-main text-center">
      <div class="col-12 my-auto">
        This service is in limited availability, please wait while your access gets approved. Thanks!
        <div class="m-4">
          <b-button @click="$router.push($app.homeRedirect)" variant="primary">Refresh</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    event: Object,
    params: Object,
  }
}
</script>